import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Alert } from "@material-ui/lab";
import { DefaultThemeProvider } from "@taycor/ui-library";
import classnames from "classnames";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { patchUpdateUser, postAddUser } from "../../../Api";
import config from "../../../config";
import { allBrandIds } from "../../../Helpers/constants";
import { checkForUserPVD } from "../../../Helpers/hooks/checkForUserPVD";
import { useBrandedPortal } from "../../../Helpers/hooks/useBrandedPortal";
import { ParentVendorDealer } from "../../../Helpers/types";
import {
  collapseUserLeadSources,
  expandUserLeadSources,
} from "../../../Helpers/users";
import { compareStrings } from "../../../Helpers/utils";
import { FormikAutocomplete } from "../../Common";
import useStyles from "./editUser.style";
import LeadSourceSelector from "./LeadSourceSelector/LeadSourceSelector";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  companyName: string;
  leadSource: string[];
  companyLogo: string;
  role: string;
  isActive: boolean;
  inventoryEnabled: boolean;
  financeApplicationUrl: string | null;
  integrationPartner: string | null;
  portalApiAccessEnabled: boolean;
  companyLogoFile?: any;
  brand: string;
  brandIds: string;
  parentVendorDealerIdObject: ParentVendorDealer[];
}

interface EditUserProps {
  vendors: Array<any>;
  brands: Array<any>;
  brandIds: any[];
  user?: User;
  onEditDone: () => void;
}

const EditUser = (props: EditUserProps) => {
  const classes = useStyles();

  const uploadRef = useRef<any>(null);
  const [userUpdated, setUserUpdated] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [vendors, setVendors] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [selectedPartner, setSelectedPartner] = useState<string>("");

  const [roles, setRoles] = useState<string[]>(["User", "Admin"]);
  const brandedPortal = useBrandedPortal();
  const isBrandedPortal = brandedPortal.isBrandedPortal;
  useEffect(() => {
    setVendors(props.vendors);
  }, [props.vendors]);
  useEffect(() => {
    if (props.user?.role) {
      if (props.user.role === "Brand Admin") {
        setRoles(["User", "Brand Admin"]);
      }
      setSelectedRole(props.user.role);
    }
    handleBrandChange(props.user?.brand || "Taycor");
    if (props.user?.integrationPartner)
      setSelectedPartner(props.user.integrationPartner);
  }, [props.user]);
  if (userUpdated) {
    return <Redirect to="/admin"></Redirect>;
  }

  const uploadIcon = (companyLogo: any, companyLogoFile: any) => {
    if (companyLogo || companyLogoFile) return <Close />;
    return <AddIcon />;
  };
  const handleBrandChange = (brand: string) => {
    brand && (brand === "Taycor" || brand === "Aztec")
      ? setRoles(["User", "Admin"])
      : setRoles(["User", "Brand Admin"]);
  };

  const getLogo = (logoFile: any, companyLogo: any) => {
    if (logoFile) return logoFile.name;
    if (companyLogo && props.user)
      return (
        <Avatar
          src={props.user.companyLogo}
          className={classes.companyLogoAvatarImg}
          alt="No Logo"
          variant="square"
          classes={{ img: classes.companyLogoAvatarImg }}
        >
          No Logo
        </Avatar>
      );
    return "Upload company logo";
  };
  const title = props.user
    ? "Edit Portal User"
    : config.pvd.enabled
    ? "Set Up New User"
    : "Taycor Admin - Portal User Set Up";

  const form = () => {
    if (checkForUserPVD() || config.pvd.enabled) {
      return (
        <DefaultThemeProvider>
          <Formik
            initialValues={{
              firstName: props.user?.firstName || "",
              lastName: props.user?.lastName || "",
              emailAddress: props.user?.emailAddress || "",
              companyName: props.user?.companyName || "",
              role: props.user ? props.user.role : "User",
              isActive: props.user ? props.user.isActive : true,
              inventoryEnabled: props.user?.inventoryEnabled || false,
              ParentVendorDealerIds: [],
              leadSourceShort: props.user?.leadSource || [""],
              brand: props.user
                ? props.user.brand !== ""
                  ? props.user.brand
                  : "Taycor"
                : "",
              brandIds: isBrandedPortal
                ? brandedPortal.brandIds
                : props.user?.brandIds.split(";") || [],
              financeApplicationUrl: props.user?.financeApplicationUrl || "",
              integrationPartner: props.user?.integrationPartner || "None",
              portalApiAccessEnabled:
                props.user?.portalApiAccessEnabled || false,
              companyLogo: props.user?.companyLogo,
              companyLogoFile: null,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              emailAddress: Yup.string()
                .email("Invalid emaill address")
                .required("Required"),
              companyName: Yup.string().required("Required"),
              financeApplicationUrl: Yup.string().url(),
              brandIds: Yup.array().required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                firstName: values.firstName,
                lastName: values.lastName,
                emailAddress: values.emailAddress,
                companyName: values.companyName,
                companyLogo: values.companyLogo,
                integrationPartner: props.user?.integrationPartner || "None",
                financeApplicationUrl: values.financeApplicationUrl,
                ParentVendorDealerIds: values.ParentVendorDealerIds.join(";"),
                leadSource: values.leadSourceShort || [""],
                role: isBrandedPortal ? "User" : values.role,
                brand: isBrandedPortal ? brandedPortal.brand : values.brand,
                brandIds: values.brandIds?.join(";"),
                isActive: values.isActive,
                inventoryEnabled: values.inventoryEnabled,
                portalApiAccessEnabled: values.portalApiAccessEnabled,
              };
              props.user
                ? patchUpdateUser(
                    Object.assign(data, { id: props.user.id }),
                    values.companyLogoFile,
                    () => {
                      setSubmitting(false);
                      setUserUpdated(true);
                      props.onEditDone();
                    },
                    (err) => {
                      setSubmitting(false);
                      setShowError(true);
                      setErrorMessage(err?.error || null);
                    }
                  )
                : postAddUser(
                    data,
                    values.companyLogoFile,
                    () => {
                      setSubmitting(false);
                      setUserUpdated(true);
                      props.onEditDone();
                    },
                    (err) => {
                      setSubmitting(false);
                      setShowError(true);
                      setErrorMessage(err?.response?.data?.error || null);
                    }
                  );
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => {
              const handleAutocompleteChange = (value: string[]) => {
                setFieldValue("ParentVendorDealerIds", value);
              };

              return (
                <Form className={classes.formPVD}>
                  <Grid container direction="column" className={classes.grid}>
                    <Typography className={classes.sectionHeader}>
                      Overview
                    </Typography>
                    <Grid item className={classes.gridItemPVD}>
                      <Typography className={classes.label}>
                        First Name
                      </Typography>
                      <Field
                        component={TextField}
                        name="firstName"
                        type="text"
                        variant="outlined"
                        size="medium"
                        margin="normal"
                        className={classes.formFieldPVD}
                      ></Field>
                    </Grid>
                    <Grid item className={classes.gridItemPVD}>
                      <Typography>Last Name</Typography>

                      <Field
                        component={TextField}
                        name="lastName"
                        type="text"
                        variant="outlined"
                        size="medium"
                        margin="normal"
                        className={classes.formFieldPVD}
                      ></Field>
                    </Grid>
                    <Grid item className={classes.gridItemPVD}>
                      <Typography>Email Address</Typography>

                      <Field
                        component={TextField}
                        name="emailAddress"
                        type="text"
                        variant="outlined"
                        size="medium"
                        margin="normal"
                        readOnly={props.user}
                        className={classes.formFieldPVD}
                      ></Field>
                    </Grid>
                    <Grid item className={classes.gridItemPVD}>
                      <Typography>Company Name</Typography>

                      <Field
                        component={TextField}
                        name="companyName"
                        type="text"
                        variant="outlined"
                        size="medium"
                        margin="normal"
                        className={classes.formFieldPVD}
                      ></Field>
                    </Grid>
                    {!isBrandedPortal && (
                      <Grid item className={classes.gridItemPVD}>
                        <FormControl
                          variant="standard"
                          size="medium"
                          margin="normal"
                          className={classes.formFieldPVD}
                        >
                          <Typography>Brand</Typography>
                          <Select
                            value={values.brand}
                            className={classes.formFieldPVD}
                            variant="outlined"
                            labelId="labelSelectBrand"
                            name="brand"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            onChange={(event: any) => {
                              setFieldValue("brand", event.target.value);
                              setFieldValue("leadSource", []);
                              handleBrandChange(event.target.value);
                            }}
                          >
                            {props.brands.map((r) => {
                              return (
                                <MenuItem key={r.name} value={r.name}>
                                  {r.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item className={classes.gridItemPVD}>
                      <Box>
                        <input
                          id="inputCompanyLogoFile"
                          name="inputCompanyLogoFile"
                          type="file"
                          hidden
                          ref={uploadRef}
                          accept="image/png, image/jpeg"
                          onChange={(event: any) => {
                            setFieldValue(
                              "companyLogoFile",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        <Box className={classes.uploadBox}>
                          <Button
                            onClick={() => {
                              if (
                                values.companyLogo ||
                                values.companyLogoFile
                              ) {
                                setFieldValue("companyLogo", null);
                                setFieldValue("companyLogoFile", null);
                              } else {
                                uploadRef.current && uploadRef.current.click();
                              }
                            }}
                          >
                            {uploadIcon(
                              values.companyLogo,
                              values.companyLogoFile
                            )}
                          </Button>
                          <Box>
                            <Typography className={classes.uploadLinkText}>
                              {getLogo(
                                values.companyLogoFile,
                                values.companyLogo
                              )}
                            </Typography>
                            <Divider
                              style={{ backgroundColor: "gray" }}
                            ></Divider>
                            <FormHelperText
                              id="companyLogoHelperText"
                              className={classes.companyLogoHelperText}
                            >
                              <Typography
                                variant="body2"
                                className={classes.helperText}
                                component="span"
                              >
                                Restrictions File Format to JPG (Family), .PNG
                              </Typography>
                            </FormHelperText>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item className={classes.gridItemPVD}>
                      <FormControl
                        variant="standard"
                        size="medium"
                        margin="normal"
                        className={classes.formFieldPVD}
                      >
                        <Typography>Partner</Typography>
                        <Field
                          component={Select}
                          variant="outlined"
                          name="integrationPartner"
                          labelId="labelIntegrationPartner"
                          className={classes.formFieldPVD}
                          value={selectedPartner}
                          onChange={(event: any) => {
                            setSelectedPartner(event.target.value);
                          }}
                        >
                          <MenuItem value="None">None</MenuItem>
                          <MenuItem value="Soarr">Soarr</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.gridItemPVD}>
                      <Typography>Finance Application URL</Typography>

                      <Field
                        component={TextField}
                        name="financeApplicationUrl"
                        type="text"
                        variant="outlined"
                        size="medium"
                        margin="normal"
                        className={classes.formFieldPVD}
                      ></Field>
                    </Grid>
                    <Divider />
                    <Typography className={classes.sectionHeader}>
                      Lead sources acces
                    </Typography>
                    <Grid container direction="column">
                      {props.user && (
                        <>
                          <Typography>Legacy Lead Source</Typography>
                          <MuiTextField
                            disabled
                            value={values.leadSourceShort}
                            className={classes.legacyLeadSource}
                            variant="outlined"
                          ></MuiTextField>
                        </>
                      )}
                      {!isBrandedPortal && (
                        <Grid item className={classes.gridItemPVD}>
                          <FormControl
                            variant="standard"
                            size="medium"
                            margin="normal"
                            className={classes.formFieldPVD}
                          >
                            <Typography>Brand Id</Typography>
                            <Select
                              value={values.brandIds}
                              className={classes.formFieldPVD}
                              variant="outlined"
                              multiple
                              labelId="labelSelectBrandId"
                              name="brandId"
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              onChange={(event: any) => {
                                if (
                                  event.target.value.includes(
                                    allBrandIds.dbName
                                  )
                                ) {
                                  setFieldValue("brandIds", [
                                    allBrandIds.dbName,
                                  ]);
                                } else {
                                  setFieldValue(
                                    "brandIds",
                                    typeof event.target.value === "string"
                                      ? event.target.value.split(",")
                                      : event.target.value
                                  );
                                  setFieldValue("leadSource", []);
                                }
                              }}
                            >
                              {props.brandIds.map((r) => {
                                if (r.name === allBrandIds.dbName) {
                                  return (
                                    <MenuItem
                                      key={allBrandIds.dbName}
                                      value={allBrandIds.dbName}
                                      className={
                                        classes.brandIdsSpecialMenuItem
                                      }
                                    >
                                      {allBrandIds.name}
                                    </MenuItem>
                                  );
                                }
                                return (
                                  <MenuItem
                                    key={r.name}
                                    value={r.name}
                                    disabled={values.brandIds.includes(
                                      allBrandIds.dbName
                                    )}
                                  >
                                    {r.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <LeadSourceSelector
                        handlePVDIds={handleAutocompleteChange}
                        brandIds={values.brandIds}
                        userPVDIds={props.user?.parentVendorDealerIdObject}
                      ></LeadSourceSelector>
                    </Grid>
                    <Divider className={classes.sectionHeader} />
                    <Typography className={classes.sectionHeader}>
                      User settings
                    </Typography>
                    {!isBrandedPortal && (
                      <Grid
                        container
                        direction="row"
                        className={classes.sectionContainer}
                      >
                        <FormControl>
                          <FormLabel>Choose User Type</FormLabel>
                          <RadioGroup
                            style={{ marginTop: 12 }}
                            defaultValue={"User"}
                            onChange={(_: any, value: any) => {
                              setFieldValue("role", value);
                              setSelectedRole(value);
                            }}
                          >
                            {roles.map((r) => {
                              return (
                                <FormControlLabel
                                  key={r}
                                  value={r}
                                  control={
                                    <Radio
                                      color="primary"
                                      checked={
                                        selectedRole === r ? true : false
                                      }
                                    />
                                  }
                                  label={r}
                                ></FormControlLabel>
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid container direction="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isActive}
                            onChange={(e) =>
                              setFieldValue("isActive", e.target.checked)
                            }
                            name="isActive"
                            color="primary"
                          />
                        }
                        label="Activate user"
                        className={classes.isActive}
                      />
                    </Grid>
                    <Grid container direction="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.inventoryEnabled}
                            onChange={(e) =>
                              setFieldValue(
                                "inventoryEnabled",
                                e.target.checked
                              )
                            }
                            name="inventoryEnabled"
                            color="primary"
                          />
                        }
                        label="Enable inventory"
                      />
                    </Grid>
                    <Grid container direction="row">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.portalApiAccessEnabled}
                            onChange={(e) =>
                              setFieldValue(
                                "portalApiAccessEnabled",
                                e.target.checked
                              )
                            }
                            name="portalApiAccessEnabled"
                            color="primary"
                          />
                        }
                        label="Enable Portal API access"
                      />
                    </Grid>
                  </Grid>
                  {isSubmitting && <LinearProgress />}
                  <Box className={classes.actions}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      disabled={isSubmitting}
                      className={classes.submitPVD}
                    >
                      {props.user ? "Edit User" : "Create User"}
                    </Button>
                  </Box>
                  <Box
                    className={classnames(classes.alert, {
                      [classes.visible]: showError,
                      [classes.hidden]: !showError,
                    })}
                  >
                    <Alert severity="error">
                      <Typography variant="h6">
                        {errorMessage ||
                          "Something went wrong, please try again"}
                      </Typography>
                    </Alert>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </DefaultThemeProvider>
      );
    } else {
      return (
        <>
          <Box>
            <Typography variant="h1">{title}</Typography>
            <Typography variant="h6" className={classes.headerSubtitle}>
              Here you can make changes or updates to the information associated
              with any of the users associated with your account. If you need
              any assistance you can reach out to us via chat by using the chat
              icon in the lower right corner of your screen. Please note that
              Company logos are limited to PNG or jpeg file types.
            </Typography>
            <Box className={classes.addIcon}>
              <Box>
                <PersonAddIcon></PersonAddIcon>
              </Box>
            </Box>
          </Box>
          <Formik
            initialValues={{
              firstName: props.user?.firstName || "",
              lastName: props.user?.lastName || "",
              emailAddress: props.user?.emailAddress || "",
              companyName: props.user?.companyName || "",
              role: props.user?.role || "User",
              isActive: props.user ? props.user.isActive : true,
              inventoryEnabled: props.user?.inventoryEnabled || false,
              leadSource: expandUserLeadSources(
                props.user?.leadSource || [],
                vendors
              ),
              ParentVendorDealerIds:
                props.user?.parentVendorDealerIdObject?.map((s) => s.id) || [],
              brand: props.user
                ? props.user.brand !== ""
                  ? props.user.brand
                  : "Taycor"
                : "",
              brandIds: props.user?.brandIds.split(";") || [],
              leadSourceShort: props.user?.leadSource || [],
              financeApplicationUrl: props.user?.financeApplicationUrl || "",
              integrationPartner: props.user?.integrationPartner || "None",
              portalApiAccessEnabled:
                props.user?.portalApiAccessEnabled || false,
              companyLogo: props.user?.companyLogo,
              companyLogoFile: null,
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              emailAddress: Yup.string()
                .email("Invalid emaill address")
                .required("Required"),
              companyName: Yup.string().required("Required"),
              leadSource: Yup.string().required("Required"),
              financeApplicationUrl: Yup.string().url(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                firstName: values.firstName,
                lastName: values.lastName,
                emailAddress: values.emailAddress,
                companyName: values.companyName,
                leadSource: values.leadSourceShort,
                ParentVendorDealerIds: values.ParentVendorDealerIds.join(";"),
                companyLogo: values.companyLogo,
                role: isBrandedPortal ? "User" : values.role,
                brand: isBrandedPortal ? brandedPortal.brand : values.brand,
                brandIds: values.brandIds?.join(";"),
                isActive: values.isActive,
                inventoryEnabled: values.inventoryEnabled,
                financeApplicationUrl: values.financeApplicationUrl,
                integrationPartner: values.integrationPartner,
                portalApiAccessEnabled: values.portalApiAccessEnabled,
              };

              props.user
                ? patchUpdateUser(
                    Object.assign(data, { id: props.user.id }),
                    values.companyLogoFile,
                    () => {
                      setSubmitting(false);
                      setUserUpdated(true);
                      props.onEditDone();
                    },
                    (err) => {
                      setSubmitting(false);
                      setShowError(true);
                      setErrorMessage(err?.error || null);
                    }
                  )
                : postAddUser(
                    data,
                    values.companyLogoFile,
                    () => {
                      setSubmitting(false);
                      setUserUpdated(true);
                      props.onEditDone();
                    },
                    (err) => {
                      setSubmitting(false);
                      setShowError(true);
                      setErrorMessage(err?.response?.data?.error || null);
                    }
                  );
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className={classes.form}>
                <Grid container direction="column" className={classes.grid}>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item className={classes.gridItem}>
                        <Field
                          component={TextField}
                          name="firstName"
                          type="text"
                          label="First Name"
                          variant="standard"
                          size="medium"
                          margin="normal"
                          className={classes.formField}
                        ></Field>
                      </Grid>
                      <Grid item className={classes.gridItem}>
                        <Field
                          component={TextField}
                          name="lastName"
                          type="text"
                          label="Last Name"
                          variant="standard"
                          size="medium"
                          margin="normal"
                          className={classes.formField}
                        ></Field>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item className={classes.gridItem}>
                        <Field
                          component={TextField}
                          name="emailAddress"
                          type="text"
                          label="Email Address"
                          variant="standard"
                          size="medium"
                          margin="normal"
                          readOnly={props.user}
                          className={classes.formField}
                        ></Field>
                      </Grid>
                      <Grid item className={classes.gridItem}>
                        <Field
                          component={TextField}
                          name="companyName"
                          type="text"
                          label="Company Name"
                          variant="standard"
                          size="medium"
                          margin="normal"
                          className={classes.formField}
                        ></Field>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      {!isBrandedPortal && (
                        <Grid item className={classes.gridItem}>
                          <FormControl
                            variant="standard"
                            size="medium"
                            margin="normal"
                            className={classes.formField}
                          >
                            <InputLabel id="labelSelectBrand">Brand</InputLabel>

                            <Select
                              value={values.brand}
                              labelId="labelSelectBrand"
                              label="Brand"
                              name="brand"
                              onChange={(event: any) => {
                                setFieldValue("brand", event.target.value);
                                "Taycor".localeCompare(event.target.value) ===
                                  1 && event.target.value
                                  ? setRoles(["User", "Brand Admin"])
                                  : setRoles(["User", "Admin"]);
                                "Taycor".localeCompare(event.target.value) ===
                                  1 && event.target.value
                                  ? setFieldValue("brandIds", ["midpoint"])
                                  : setFieldValue("brandIds", ["taycor"]);
                              }}
                            >
                              {props.brands.map((r) => {
                                return (
                                  <MenuItem value={r.name}>{r.name}</MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item className={classes.gridItem}>
                        <Field
                          component={FormikAutocomplete}
                          options={
                            isBrandedPortal
                              ? props.vendors
                                  .map((v) => v.name)
                                  .sort(compareStrings)
                              : vendors.map((v) => v.name)
                          }
                          name="leadSource"
                          textFieldProps={{
                            type: "text",
                            variant: "standard",
                            size: "medium",
                            margin: "normal",
                            label: "Lead Source",
                          }}
                          onChange={(_: any, value: any) => {
                            const newValues = expandUserLeadSources(
                              value,
                              vendors,
                              [...values.leadSource]
                            );
                            setFieldValue("leadSource", newValues);
                            setFieldValue(
                              "leadSourceShort",
                              collapseUserLeadSources(newValues)
                            );
                          }}
                          className={classes.formField}
                        ></Field>
                        <FormHelperText>
                          <Typography
                            variant="body2"
                            className={classes.helperText}
                            component="span"
                          >
                            SQL Compatible CSV List in Quotes
                          </Typography>
                        </FormHelperText>
                      </Grid>
                      <Grid item className={classes.gridItem}>
                        <Field
                          component="div"
                          aria-describedby="companyLogoHelperText"
                          className={classes.logoFormField}
                        >
                          <Button
                            component="label"
                            variant="text"
                            endIcon={<AddIcon></AddIcon>}
                            className={classes.upload}
                          >
                            {getLogo(
                              values.companyLogoFile,
                              values.companyLogo
                            )}
                            <input
                              id="inputCompanyLogoFile"
                              name="inputCompanyLogoFile"
                              type="file"
                              onChange={(event: any) => {
                                setFieldValue(
                                  "companyLogoFile",
                                  event.currentTarget.files[0]
                                );
                              }}
                              style={{ display: "none" }}
                              accept="image/png, image/jpeg"
                            />
                          </Button>
                          {(values.companyLogo || values.companyLogoFile) && (
                            <Link
                              variant="body2"
                              onClick={() => {
                                setFieldValue("companyLogo", null);
                                setFieldValue("companyLogoFile", null);
                              }}
                              className={classes.removeLogoLink}
                            >
                              remove
                            </Link>
                          )}
                        </Field>
                        <FormHelperText
                          id="companyLogoHelperText"
                          className={classes.companyLogoHelperText}
                        >
                          <Typography
                            variant="body2"
                            className={classes.helperText}
                            component="span"
                          >
                            Restrictions File Format to JPG (Family), .PNG
                          </Typography>
                        </FormHelperText>
                      </Grid>
                      {!isBrandedPortal && (
                        <Grid item className={classes.gridItem}>
                          <FormControl
                            variant="standard"
                            size="medium"
                            margin="normal"
                            className={classes.formField}
                          >
                            <InputLabel id="labelSelectRole">
                              User Type
                            </InputLabel>
                            <Field
                              component={Select}
                              name="role"
                              value={selectedRole}
                              labelId="labelSelectRole"
                              label="User Type"
                              onChange={(event: any) => {
                                setFieldValue("role", event.target.value);
                                setSelectedRole(event.target.value);
                              }}
                            >
                              {roles.map((r) => {
                                return <MenuItem value={r}>{r}</MenuItem>;
                              })}
                            </Field>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item className={classes.gridItem}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isActive}
                              onChange={(e) =>
                                setFieldValue("isActive", e.target.checked)
                              }
                              name="isActive"
                              color="primary"
                            />
                          }
                          label="Is Active"
                          className={classes.isActive}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item className={classes.gridItem}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.inventoryEnabled}
                            onChange={(e) =>
                              setFieldValue(
                                "inventoryEnabled",
                                e.target.checked
                              )
                            }
                            name="inventoryEnabled"
                            color="primary"
                          />
                        }
                        label="Inventory Enabled"
                        className={classes.isActive}
                      />
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <Field
                        component={TextField}
                        name="financeApplicationUrl"
                        type="text"
                        label="Finance Application Url"
                        variant="standard"
                        size="medium"
                        margin="normal"
                        readOnly={props.user}
                        className={classes.formField}
                      ></Field>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item className={classes.gridItem}>
                      <FormControl
                        variant="standard"
                        size="medium"
                        margin="normal"
                        className={classes.formField}
                      >
                        <InputLabel id="labelSelectPartmer">Partner</InputLabel>
                        <Field
                          component={Select}
                          name="integrationPartner"
                          labelId="labelIntegrationPartner"
                          label="User Type"
                          value={selectedPartner}
                          onChange={(event: any) => {
                            setSelectedPartner(event.target.value);
                          }}
                        >
                          <MenuItem value="None">None</MenuItem>
                          <MenuItem value="Soarr">Soarr</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.portalApiAccessEnabled}
                            onChange={(e) =>
                              setFieldValue(
                                "portalApiAccessEnabled",
                                e.target.checked
                              )
                            }
                            name="portalApiAccessEnabled"
                            color="primary"
                          />
                        }
                        label="Portal API access enabled"
                        className={classes.isActive}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {isSubmitting && <LinearProgress />}
                <Box className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                </Box>
                <Box
                  className={classnames(classes.alert, {
                    [classes.visible]: showError,
                    [classes.hidden]: !showError,
                  })}
                >
                  <Alert severity="error">
                    <Typography variant="h6">
                      {errorMessage || "Something went wrong, please try again"}
                    </Typography>
                  </Alert>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      );
    }
  };

  return (
    <Box className={classes.root}>
      <Box>
        {/* <Typography variant="h6" className={classes.headerSubtitle}>
          Here you can make changes or updates to the information associated
          with any of the users associated with your account. If you need any
          assistance you can reach out to us via chat by using the chat icon in
          the lower right corner of your screen. Please note that Company logos
          are limited to PNG or jpeg file types.
        </Typography> */}
      </Box>
      {form()}
    </Box>
  );
};

export default EditUser;
